/** @jsx jsx */

import PropTypes from 'prop-types'
import React from 'react'
import { css, jsx } from '@emotion/react'
import { MobileMenu } from './Mobilemenu'

import { colors, variables } from '../styles/global'
import DesktopMenu from './DesktopMenu'

interface Props {
  siteTitle: string
}

const Header = ({ siteTitle }: Props) => (
  <header css={css``}>
    <MobileMenu />
    <DesktopMenu />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
