/** @jsx jsx */

import { HiMenu, HiMenuAlt2 } from 'react-icons/hi'
import { Link } from 'gatsby'

import { GrClose } from 'react-icons/gr'
import { globalCss, variables, colors } from '../styles/global'
import { Global, css, jsx } from '@emotion/react'
import React, { useState, useEffect, useRef } from 'react'
import { animated, useSpring } from 'react-spring'
import { FaInstagram } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'

export function MobileMenu() {
  const url = typeof window !== 'undefined' ? window.location.href : ''

  const [open, setOpen] = useState(false)
  const [showMenuBar, setShowMenuBar] = useState(true)
  const showRef = useRef(true)
  const lastPosRef = useRef(0)
  const scrollMargin = 40
  const hideMenuBelow = 200

  function handleScroll() {
    if (window.scrollY > lastPosRef.current && window.scrollY > hideMenuBelow) {
      if (showRef.current) {
        showRef.current = false
        setShowMenuBar(false)
      }
      lastPosRef.current = window.scrollY
    }
    if (window.scrollY < lastPosRef.current || window.scrollY < hideMenuBelow) {
      if (!showRef.current) {
        showRef.current = true
        setShowMenuBar(true)
      }
      lastPosRef.current = window.scrollY
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <div
      css={css`
        ${variables.desktop} {
          display: none;
        }
      `}
    >
      <nav
        css={css`
          position: fixed;
          width: 100vw;
          height: 100vh;
          background-color: ${colors.backgroundColor};
          opacity: ${open ? 1 : 0};
          pointer-events: ${open ? 'auto' : 'none'};
          transition: opacity 0.4s;
          z-index: 5;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          padding-top: 100px;
        `}
      >
        <MobileMenuItem
          to="/"
          text="Beeld"
          selected={url.includes('/portfolio')}
        />
        {/* <MobileMenuItem
          to="/woord"
          text="Woord"
          selected={url.includes('/woord')}
        /> */}
        <MobileMenuItem
          to="/over"
          text="Info / Contact"
          selected={url.includes('/over')}
        />
        {/* <MobileMenuItem
          to="/blog"
          text="Blog"
          selected={url.includes('/blog')}
        /> */}
        <MobileMenuItem
          to="/events"
          text="Events"
          selected={url.includes('/events')}
        />
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <a
            href="https://www.instagram.com/ik_maak_anders_iets/"
            css={css`
              margin-right: 1rem;
            `}
          >
            <FaInstagram
              color={colors.textColor}
              className="react-icons"
              css={css`
                width: 20px;
                height: 20px;
              `}
            />
          </a>

          <a
            href="mailto:marjanderidder@live.be"
            css={css`
              margin-left: 1rem;
            `}
          >
            <FiMail
              color={colors.textColor}
              className="react-icons"
              css={css`
                width: 20px;
                height: 20px;
              `}
            />
          </a>
        </div>
      </nav>
      <div
        css={css`
          position: fixed;
          top: ${showMenuBar ? '0px' : '-65px'};
          transition: top 0.4s;
          width: 100vw;
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          z-index: 10;
          background-color: ${colors.backgroundColor};
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
        `}
      >
        <span
          css={css`
            margin-left: 10px;
          `}
        >
          MARJAN DE RIDDER
        </span>
        <div
          css={css`
            margin-right: 10px;
            cursor: pointer;
            opacity: 0.6;
          `}
          onClick={() => setOpen(!open)}
        >
          {!open ? (
            <HiMenuAlt2
              color={colors.textColor}
              css={css`
                width: 30px;
                height: 30px;
              `}
            />
          ) : (
            <GrClose
              color={colors.textColor}
              css={css`
                width: 25px;
                height: 25px;
              `}
            />
          )}
        </div>
      </div>
    </div>
  )
}
type MobileMenuProps = {
  to: string
  text: string
  selected: boolean
}

function MobileMenuItem({ to, text, selected }: MobileMenuProps) {
  return (
    <div
      css={css`
        margin-bottom: 2rem;
        font-size: 1.2rem;
        color: ${colors.textColor};
        position: relative;
      `}
    >
      <Link to={to} className="navLinkMobile">
        {text}
      </Link>
      <div
        css={css`
          width: ${selected ? '100%' : '0px'};
          transition: width 0.4s;
          opacity: ${selected ? 1 : 0.5};
          position: absolute;
          left: 0;
          bottom: 0;
          height: 1px;
          background-color: ${colors.textColor};
        `}
      ></div>
    </div>
  )
}
