/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import React from 'react'
import { FaInstagram } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'
import { colors } from '../styles/global'

export default function Footer() {
  return (
    <footer
      css={css`
        margin-top: 80px;
        font-size: 1rem;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        align-items: flex-end;
        background-color: ${colors.backgroundColor};
      `}
    >
      <a
        href="https://www.instagram.com/ik_maak_anders_iets/"
        css={css`
          margin-bottom: 5px;
          display: inline-flex;
          align-items: center;
        `}
      >
        <b>Instagram -&nbsp;</b>
        <FaInstagram color={colors.textColor} className="react-icons" />
      </a>
      <a
        href="mailto:marjanderidder@live.be"
        css={css`
          margin-bottom: 15px;
          display: inline-flex;
          align-items: center;
        `}
      >
        <b>Mail -&nbsp;</b>
        <FiMail color={colors.textColor} className="react-icons" />
      </a>
      <p
        css={css`
          font-size: 0.8rem;
          opacity: 0.6;
        `}
      >
        © Marjan de Ridder, All rights reserved
      </p>
    </footer>
  )
}
