export const colors = {
  backgroundColor: '#f7f7f7',
  backgroundColorDarker: '#f7f7f7',
  textColor: '#353535',
  borderColor: 'rgb(0, 0, 0)',
}

export const variables = {
  siteWidthDesktop: '1400px',
  breakPoint: 700,
  notFullWidth: '@media (max-width: 900px)',
  desktop: '@media (min-width: 700px)',
  mobile: '@media (max-width: 699px)',
}

export const globalCss = `
*{
    color: ${colors.textColor};
    font-family: Helvetica;
    user-select: none;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events:none;
  z-index: -1;
}

body {
  overscroll-behavior: none;
            background-color: ${colors.backgroundColor};
           
            background-size: cover;
            background-attachment: fixed;
}

.container {
  margin-top: 40px;
}

.contentContainer{
  background-color: ${colors.backgroundColor};
  padding: 30px;
  z-index: 100;
}

a{
  text-decoration: none;
}

footer a{
  opacity: 0.6;
  transition: all 0.3s
}

footer a:hover{
  opacity: 1;
}


.navLinkMobile {
  text-decoration: none;
  margin-left: 20px;
  font-size: 2rem;
}

.arrowContainer{
    position: relative;
  width: 60px;
  &:hover{
    cursor: pointer;
  }
}


.arrowButton {
height: 20px;
position: absolute;
left: 50%;
top: 0px;
transform: translate(-50%, -50%);
transition: height 0.3s;
&:hover{
  height: 25px;
}
}

.show{ opacity: 1;}
.hide{opacity: 0;}

.react-icons {
  vertical-align: middle;
}

.homepageLink{
  height: 3vw;
  display: flex;
  align-items: center
}

.homepageLink:hover{
  cursor: pointer;
}
.homepageLink:hover .podlood{
  opacity: 1;
}

.navLink{
  height: 20px;
  display: flex;
  align-items: center
  display: block;
  opacity: 1;
  transition: opacity 0.2s;
  text-decoration: none;
  margin-left: 1%;
  margin-right: 10px;
}

.navLink:hover{
  cursor: pointer;
  opacity: 1;
}
.navLink:hover .podlood{
  opacity: 1;
}

.podlood {
  display: block;
  width: 4vw;
  height: 100%;
  background-image: url('/podlood.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  transition: opacity 0.4s;
}

.navPointer {
  width: 3vw;
}

.podloodLinks {
  margin-right: 5px;
}

.podloodRechts {
  margin-left: 5px;
  transform: scaleX(-1);
}



.homepageImg{
  height: 3vw;
}

.titleImg {
  height: 80px;
  margin-bottom: 40px;
}


${variables.mobile}{
  .homepageImg{
    height: 4.5vw;
  }
}
`
