/** @jsx jsx */

import React, { useState } from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'gatsby'
import { colors, variables } from '../styles/global'
import { FaInstagram } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'

export default function DesktopMenu() {
  const url = typeof window !== 'undefined' ? window.location.href : ''

  return (
    <nav
      css={css`
        ${variables.mobile} {
          display: none;
        }
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
      `}
    >
      {/* <Link to="" className="navLink">
            Shop
          </Link> */}
      <Link to="/">
        <span
          css={css`
            font-size: 3rem;
            font-weight: 400;
            margin-top: 5rem;
            display: block;
          `}
        >
          MARJAN DE RIDDER
        </span>
      </Link>
      <div
        css={css`
          display: flex;
          justify-content: center;
          margin-top: 0.6rem;
          font-size: 1.2rem;
          font-weight: 300;
        `}
      >
        <MenuItem
          to="/"
          text="Beeld"
          selected={
            url.includes('/portfolio') ||
            (!url.includes('/over') && !url.includes('/events'))
          }
        />
        {/* <MenuItem to="/woord" text="Woord" selected={url.includes('/woord')} /> */}
        <MenuItem
          to="/over"
          text="Info / Contact"
          selected={url.includes('/over')}
        />
        {/* <MenuItem to="/blog" text="Blog" selected={url.includes('/blog')} /> */}
        <MenuItem
          to="/events"
          text="Events"
          selected={url.includes('/events')}
        />
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          margin-top: 30px;
          opacity: 0.6;
        `}
      >
        <a
          href="https://www.instagram.com/ik_maak_anders_iets/"
          css={css`
            margin-right: 1rem;
          `}
        >
          <FaInstagram
            color={colors.textColor}
            className="react-icons"
            css={css`
              width: 20px;
              height: 20px;
            `}
          />
        </a>
        <a
          href="mailto:marjanderidder@live.be"
          css={css`
            margin-left: 1rem;
          `}
        >
          <FiMail
            color={colors.textColor}
            className="react-icons"
            css={css`
              width: 20px;
              height: 20px;
            `}
          />
        </a>
      </div>
      {/* <Link to="/" className="navLink">
            Home
          </Link> */}
    </nav>
  )
}

function MenuItem({
  to,
  text,
  selected,
}: {
  to: string
  text: string
  selected: boolean
}) {
  const [hover, setHover] = useState(false)

  return (
    <Link to={to} className="navLink">
      <span
        css={css`
          position: relative;
          display: block;
        `}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {text}

        <div
          css={css`
            width: ${selected ? '100%' : hover ? '100%' : '0px'};
            transition: width 0.4s;
            opacity: ${selected ? 1 : 0.5};
            position: absolute;
            left: 0;
            bottom: 0;
            height: 1px;
            background-color: ${colors.textColor};
          `}
        ></div>
      </span>
    </Link>
  )
}
