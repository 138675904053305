/** @jsx jsx */

import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './Header'

import { variables, colors } from '../styles/global'
import { css, jsx } from '@emotion/react'
import Footer from './Footer'

import '../styles/index.css'
import '../styles/reset.css'

type LayoutProps = {
  children: ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div
      css={css`
        background-color: ${colors.backgroundColor};
        color: ${colors.textColor};
        min-height: 100vh;
      `}
    >
      <Header />

      <main
        css={css`
          margin: auto;
          max-width: ${variables.siteWidthDesktop};
          border-radius: 5px;
          padding: 50px 50px 70px;
          z-index: 5;
          ${variables.mobile} {
            padding: 100px 20px 70px;
          }
        `}
      >
        {children}
      </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
